import React, { FC, ReactComponentElement, useState, useEffect } from 'react'
import { ProductSwatches } from '@magal/components'
import {
  AdvancedProductOption,
  Option,
  ShopifyProductColor,
} from '@magal/models'
import { styled } from '@magal/styles'
import { ProductOption } from './ProductOption'
import { useProductsSettings } from '@magal/product'
import { useTranslation } from 'react-i18next'
import { ProductUploadFile } from './ProductUploadFile'
import { ProductMap } from './ProductMap/ProductMap'
import { ProductCharms } from './ProductCharms/ProductCharms'
import { AddLinesInput } from '@magal/models'
import { useCartContext } from '@magal/cart'
import { GTMAddToCart } from '@magal/services/gtm-service'

const Root = styled('div', {
  display: 'grid',
  pCardProductrojectFont: 'body04',
  gap: '$16',
  color: '$green_09',
  padding: '$12 0',
  '@lg': {
    gap: '$12',
    padding: '$28',
    borderRadius: '$r2',
    background: '$gray300',
  },
})
const ColorsWrap = styled('div', {
  display: 'grid',
  gridGap: '$16',
  placeContent: 'center',
})
const Title = styled('div', {
  projectFont: 'body05',
  textAlign: 'center',
  userSelect: 'none',
  marginBottom: '$12',
})
const SelectedVal = styled('div', {
  projectFont: 'caps07',
  height: '1em',
  textAlign: 'center',
})
const OptionWrap = styled('div', {
  padding: '$8 0 $16',
  borderBottom: '1px solid $gray500',
  marginBottom: '$16',
})
const handleAddToCartCharm = async (
  items: {
    id: string
    letter?: string
  }[],
  t: any,
  addCartLines: any,
  openMiniCart: (t: any) => void,
) => {
  if (items && items.length > 0) {
    const linesToAdd: AddLinesInput = items.map((item) => {
      const itemToAdd: {
        merchandiseId: string
        quantity: number
        attributes?: any
      } = {
        merchandiseId: item.id,
        quantity: 1,
        attributes: [],
      }

      if (item.letter) {
        itemToAdd.attributes = [
          {
            key: t('selectedLetter'),
            value: item.letter,
          },
        ]
      }

      return itemToAdd
    })

    const addedLines = await addCartLines(linesToAdd)

    if (addedLines) {
      GTMAddToCart(addedLines[0], 1)

      setTimeout(() => openMiniCart(t('addedToCart')), 200)
    }
  }
}

export const OptionWithTitle: FC<{ title: string; index: number }> = ({
  title,
  index,
  children,
}) => {
  return (
    <OptionWrap>
      <Title>
        {index + 1}. {title === ' _uploadedFile:' ? 'Upload File' : title}
      </Title>
      {children}
    </OptionWrap>
  )
}

export const ProductWizardStacked: FC<{
  template: string | undefined
  productColors?: ShopifyProductColor[]
  options?: AdvancedProductOption[]
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
  onDone: (checkAllDone?: any) => void
  currentProductColorId?: string
  addToCart: ReactComponentElement<any>
  productAttributes: {
    key: string
    value: string
  }[]
  setExtraPrice: (sum: any) => void
}> = ({
  template,
  productColors,
  options,
  currentProductColorId,
  productAttributes,
  onChange,
  onDone,
  setExtraPrice,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [productCharms, setProductCharms] = useState<any[]>([])
  const { colorsConfig } = useProductsSettings()
  const { t } = useTranslation('sectionProductConversionArea')
  const { addCartLines, closeMiniCart, openMiniCart } = useCartContext()

  type ComputedOption = AdvancedProductOption | Option<'product-colors'>

  useEffect(() => {
    const sumCharmPrices = productCharms.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.price),
      0,
    )

    setExtraPrice(sumCharmPrices || 0)

    if (productCharms.length <= 0) return

    const eventHandle = async (event: any) => {
      const { detail } = event
      if (detail[0]?.merchandiseId) {
        closeMiniCart()
        setTimeout(
          async () =>
            await handleAddToCartCharm(
              productCharms,
              t,
              addCartLines,
              openMiniCart,
            ),
          200,
        )
      }
    }

    window.addEventListener('product::added', eventHandle)

    return () => {
      window.removeEventListener('product::added', eventHandle)
    }
  }, [productCharms])

  if (!options || options.length === 0) return null

  const computedOptions: ComputedOption[] = (() => {
    if (productColors) {
      return [
        {
          _type: 'product-colors',
          _key: 'product-colors',
          title: t('chooseMaterial'),
          selectedValue: currentProductColorId,
        },
        ...options,
      ]
    } else {
      return options
    }
  })()

  const numberOfIris = (options: AdvancedProductOption[], multiplier = '') => {
    if (!multiplier || multiplier === '') return 1

    const findMultiplier = options.find(
      (option: AdvancedProductOption) => option.title === multiplier,
    )

    if (findMultiplier) {
      const findNumber = findMultiplier.selectedValue?.replace(/[^0-9]/g, '')

      return Number(findNumber)
    }

    return 1
  }

  const advancedTemplate = template === 'ADVANCED_TEMPLATE'

  const staticOptions: ComputedOption[] = computedOptions.filter(
    (o: ComputedOption) =>
      o.title &&
      (o.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) ||
        o.title.match(/\d+/) === null),
  )

  const isSizePicker = (title: string): boolean =>
    ['size', 'length', 'longueur'].some((option: string) =>
      title.toLowerCase().includes(option),
    )

  const allButLastOptions: ComputedOption[] = staticOptions.filter(
    (o: ComputedOption, i: number) =>
      !(i === staticOptions.length - 1 && isSizePicker(o.title)),
  )

  const lastOption: ComputedOption[] = staticOptions.filter(
    (o: ComputedOption, i: number) =>
      i === staticOptions.length - 1 && isSizePicker(o.title),
  )

  const fixedOptions: ComputedOption[][] = allButLastOptions.map(
    (o: ComputedOption) => Array.of(o),
  )

  const groupItems = (
    arr: AdvancedProductOption[],
  ): AdvancedProductOption[][] => {
    const temp = []
    for (let i = 1; i <= 10; i++) {
      temp.push(
        arr.filter((option: AdvancedProductOption) => {
          return (
            parseInt(`${option.title.match(/\d+/)}`) === i &&
            option.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) === null
          )
        }),
      )
    }

    return temp.filter((o) => o.length > 0)
  }

  const myOptions = groupItems(options)
  const myOptionsUseful = myOptions.length > 0 && myOptions[0].length > 1
  const myOptionsArr = computedOptions.map((i) => [i])

  let productOptions: ComputedOption[][] = myOptionsUseful
    ? [...fixedOptions, ...myOptions]
    : myOptionsArr

  const checkMapAndMarker = () => {
    const mapOpt = productOptions.find((opt) => opt[0]._type === 'map')

    if (!mapOpt) return [false, false]

    const hasMarker = mapOpt[0].mapMarker && mapOpt[0].mapMarker !== ''

    return [!!mapOpt, hasMarker, mapOpt[0].mapMarker]
  }

  const hasMapAndMarker = checkMapAndMarker()
  let markerOption: ComputedOption | null = null
  let hasDiamond: ComputedOption | null = null

  if (hasMapAndMarker[0] && hasMapAndMarker[1]) {
    if (hasMapAndMarker[2]) {
      const theMarkerOption = productOptions.find(
        (opt) => opt[0].title === hasMapAndMarker[2],
      )

      if (theMarkerOption) {
        markerOption = theMarkerOption[0]
      } else {
        const lookForDiamond = productOptions.find((opt) =>
          opt[0].title.toLowerCase().includes('diamond'),
        )

        if (lookForDiamond) {
          hasDiamond = lookForDiamond[0]
        }
      }
    }

    productOptions = productOptions.filter(
      (po) => po[0].title !== hasMapAndMarker[2],
    )
  }

  if (lastOption.length > 0 && myOptionsUseful) productOptions.push(lastOption)

  let currentGroup: ComputedOption[] = []

  let checkAllDone = computedOptions.every(
    (option: ComputedOption) => option.selectedValue !== '',
  )

  if (advancedTemplate) {
    currentGroup = productOptions[currentStepIndex]

    checkAllDone = productOptions.every((optionGroup: ComputedOption[]) => {
      const filteredOptionGroup = optionGroup.filter(
        (og) => og._type !== 'charms',
      )

      return filteredOptionGroup.every(
        (option: ComputedOption) =>
          (option.selectedValue && option.selectedValue !== '') ||
          option.values === null,
      )
    })
  }

  const multipleOptionsTitle = (arr: ComputedOption[], index = 0): string => {
    const ordinals = ['', 'st', 'nd', 'rd', 'th']
    let prefix = 0
    const group = arr.map((step: ComputedOption) => {
      const theNumber =
        step.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) === null &&
        step.title.match(/\d+/)
      prefix = theNumber ? parseInt(`${theNumber[0]}`) : 0

      return theNumber ? step.title.replace(/[0-9]/g, '') : step.title
    })

    const ordinalsIndex =
      prefix >= ordinals.length ? ordinals.length - 1 : prefix

    return `${prefix > 0 ? `${t('choose')} ${prefix}` : ''}${
      ordinals[ordinalsIndex]
    } ${group[index]}:`
  }

  if (currentGroup.length > 1 && currentGroup[1]._type === 'multi-text-input') {
    currentGroup.reverse()
  }

  const secondTitle =
    currentGroup.length > 1 ? multipleOptionsTitle(currentGroup, 1) : ''

  onDone(checkAllDone)

  return (
    <Root>
      {!advancedTemplate &&
        computedOptions?.map((option, i) => {
          if (option._type === 'product-colors') {
            return (
              <OptionWithTitle title={option.title} index={i}>
                <ColorsWrap key={'product-colors'}>
                  <ProductSwatches
                    currentProductColorId={currentProductColorId}
                    productColors={productColors}
                    preventScroll
                  />
                  <SelectedVal>
                    {currentProductColorId &&
                      colorsConfig &&
                      colorsConfig[currentProductColorId]?.label}
                  </SelectedVal>
                </ColorsWrap>
              </OptionWithTitle>
            )
          } else {
            return (
              <ProductOption
                secondTitle={secondTitle}
                option={option}
                key={option.title}
                onChange={onChange}
                onGoToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)}
              />
            )
          }
        })}

      {advancedTemplate &&
        productOptions?.map((option: ComputedOption[], i) => {
          return option.map((opt: ComputedOption) => {
            if (opt._type === 'product-colors') {
              return (
                <OptionWithTitle title={opt.title} index={i}>
                  <ColorsWrap key={i + 'product-colors'}>
                    <ProductSwatches
                      currentProductColorId={currentProductColorId}
                      productColors={productColors}
                      preventScroll
                    />
                    <SelectedVal>
                      {currentProductColorId &&
                        colorsConfig &&
                        colorsConfig[currentProductColorId]?.label}
                    </SelectedVal>
                  </ColorsWrap>
                </OptionWithTitle>
              )
            } else if (opt._type === 'upload-file') {
              return (
                <OptionWithTitle title={opt.title} index={i}>
                  <ProductUploadFile
                    key={i}
                    onChange={onChange}
                    option={opt}
                    numberOfFiles={
                      numberOfIris(options, opt.multiplierOptionTitle) || 1
                    }
                  />
                </OptionWithTitle>
              )
            } else if (opt._type === 'map') {
              return (
                <ProductMap
                  key={i}
                  productAttributes={productAttributes}
                  productColor={currentProductColorId}
                  option={opt}
                  onChange={onChange}
                  markerOption={markerOption}
                  hasDiamond={hasDiamond}
                />
              )
            } else if (opt._type === 'charms' && opt.charmItems) {
              return (
                <OptionWithTitle title={opt.title} index={i}>
                  <ProductCharms
                    key={i}
                    onChange={onChange}
                    option={opt}
                    charms={opt.charmItems}
                    onSelect={setProductCharms}
                  />
                </OptionWithTitle>
              )
            } else {
              return (
                <OptionWithTitle title={opt.title} index={i}>
                  <ProductOption
                    secondTitle={secondTitle}
                    option={opt}
                    key={opt.title}
                    onChange={onChange}
                    onGoToNextStep={() =>
                      setCurrentStepIndex(currentStepIndex + 1)
                    }
                  />
                </OptionWithTitle>
              )
            }
          })
        })}
    </Root>
  )
}
