import { FC } from 'react'
import { styled } from '@magal/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css/autoplay'
import { Media } from '@magal/components'
import { MediaFieldType } from '@magal/models'

const ShippingArea = styled('div', {
  paddingBottom: '$16',
})

const ShippingIcons = styled('div', {
  marginRight: '7px',
  display: 'block',
  width: '22px',
})

const ShippingText = styled('div', {
  projectFont: 'body02',
  fontWeight: '500',
  textAlign: 'center',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    list: {
      true: {
        justifyContent: 'flex-start',
        display: 'flex',
        padding: '$2 $6',
        borderRadius: '$r2',
        marginBottom: '$8',
      },
    },
  },
})

const SliderWrapper = styled('div', {
  width: '100%',
  maxWidth: 'calc(100vw - 32px)',
  padding: '0 0 $8',
  boxSizing: 'content-box',
  overflowX: 'scroll',
  '@lg': { maxWidth: '30vw' },
})

const ShippingList = styled('div', {
  border: '1px solid $green_09',
  borderRadius: '$r1',
  padding: '$12 $12 $2',
})

export const Reassurance: FC<{
  items: { title: string; icon: MediaFieldType }[]
  list?: boolean | null
}> = ({ list = false, items = [] }) => {
  return (
    <ShippingArea>
      {list ? (
        <ShippingList>
          {items.map((item, index) => (
            <ShippingText list={list} key={index}>
              <ShippingIcons>
                <Media {...item.icon} sizes={'25px'} />
              </ShippingIcons>
              {item.title}
            </ShippingText>
          ))}
        </ShippingList>
      ) : (
        <SliderWrapper>
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <ShippingText>
                  <ShippingIcons>
                    <Media {...item.icon} sizes={'25px'} />
                  </ShippingIcons>
                  {item.title}
                </ShippingText>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderWrapper>
      )}
    </ShippingArea>
  )
}
